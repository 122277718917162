import { defineComponent } from 'vue';
import BaseIcon from '../atoms/BaseIcon.vue';
export default defineComponent({
    name: 'CardComponent',
    components: { BaseIcon },
    props: {
        url: String,
        altText: String,
    },
});
